@import url('https://fonts.googleapis.com/css?family=Play');

@font-face {
  font-family: "Sansation";
  src: url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.eot');
  src: url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.eot') format('embedded-opentype'),url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.ttf') format('truetype'),url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.svg') format('svg');
}

body {
  margin: 0;
  font-family: 'Sansation';
  font-size: calc(18px + 2vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: green;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
