.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.Footer {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
