@import url(https://fonts.googleapis.com/css?family=Play);
@font-face {
  font-family: "Sansation";
  src: url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.eot');
  src: url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.eot') format('embedded-opentype'),url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.ttf') format('truetype'),url('https://wzuk.blob.core.windows.net/assets/fonts/Sansation_Regular.svg') format('svg');
}

body {
  margin: 0;
  font-family: 'Sansation';
  font-size: calc(18px + 2vmin);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: green;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  color: white;
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 75vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.Footer {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

